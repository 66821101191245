<template>
  <div>
    <h1 class="text-4xl text-center py-5">{{ page.title }}</h1>
    <container max-w="6xl mx-10">
      <div v-html="page.content"></div>
    </container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Container from "../layouts/container";

export default {
  name: "Page",
  components: {
    Container,
  },
  props:[ 'slug' ],
  computed: {

    page(){
      return this.$store.state.pages.pages[this.slug] || {}
    }

  },
  created() {
    this.$store.dispatch( 'pages/setPage' , this.slug );
  },
};
</script>

<style scoped>

</style>