<template>
  <page slug="refund-policy"></page>
</template>

<script>

import Page from "../../components/Page.vue";

export default {
  name: "RefundPolicy",
  components: {Page},
};

</script>

<style scoped>

</style>
